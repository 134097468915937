import Image from "next/image";

import React, { Component, RefObject, SyntheticEvent } from "react";
import { Inter } from "next/font/google";

import ranking from "../res/drawable/ranking3.png";
import site_banner from "../res/drawable/logo/site_banner.png";
import { NextRouter, withRouter } from "next/router";
import ProductList from "@/component/molecules/ProductList";
import { PropsFromRedux, connector } from "@/redux/module/product/ProductProps";
import Footer from "@/component/molecules/Footer";
import PromotionSlide from "@/component/molecules/PromotionSlide";
import { Typography, ImageListItem, Card, Button } from "@mui/material";
import {
  orangePrimary,
  primaryProductNode,
  primary,
  primaryText,
} from "@/styles/theme";
import MainCategoryTab from "@/component/molecules/MainCategoryTab";
import Constants from "@/Utils/Constants";
import ProductKeywordList from "@/component/molecules/ProductKeywordList";
import RecommendListByUser from "@/component/molecules/RecommendListByUser";
import NoticeList from "@/component/molecules/NoticeList";
import ExhibitionList from "@/component/atoms/ExhibitionList";
import ProductListHorizontal from "@/component/molecules/ProductListHorizontal";
import { getSSR } from "@/redux/module/api";
import { CategoryNodePropsType } from "@/types/ProductTypes";
import FavoriteCategoryList from "@/component/molecules/FavoriteCategoryList";
import NormalBox from "@/component/molecules/NormalBox";
import { getRGBAValue } from "@/Utils/Utils";
import TopMenuBar from "@/component/molecules/TopMenuBar";
import ImageRowList from "@/component/atoms/ImageRowList";
import Festival from "@/component/molecules/Festival";
import BaseComponent from "@/component/base/BaseComponent";
import PostList from "@/component/molecules/PostList";
import PostWithSlide from "@/component/molecules/PostWithSlide";
import dynamic from "next/dynamic";

const inter = Inter({ subsets: ["latin"] });

interface Props {
  router: NextRouter;
  favoriteList: CategoryNodePropsType[];
}
type CombinedProps = PropsFromRedux & Props;

interface StateType {
  // isMobile: boolean;
}

export async function getServerSideProps(context: any) {
  const { req } = context;
  const cookies = req.headers.cookie || "";

  const apiUrl = "member/favorite";

  let res = null;
  let favoriteList = null;

  try {
    res = await getSSR(apiUrl, {}, cookies);
    favoriteList = res.status == 200 ? res.data.resData : null;
  } catch (error) {
    return {
      props: {
        favoriteList,
      },
    };
  }

  return {
    props: {
      favoriteList,
    },
  };
}
class index extends BaseComponent<CombinedProps, StateType> {
  ParticleShaderCanvas = dynamic(
    () => import("../component/effect/ParticleShaderCanvas"),
    { ssr: false }
  );

  componentDidMount = () => {
    super.componentDidMount();
    if (location.href.includes("new.kirakiratomo.com")) {
      alert("키라키라토모 신규 사이트로 이동합니다.");
      location.href = "https://kirakira-tomo.com";
    }
  };

  render() {
    const { favoriteList } = this.props;
    return (
      <div
        className="centerContainer"
        style={{
          marginTop: "39px",
        }}
      >
        {/* <this.ParticleShaderCanvas /> */}
        <img
          src={site_banner.src}
          style={{
            width: "100%",
            height: "auto",
            position: "relative",
            zIndex: 10,
          }}
        />

        <PromotionSlide />
        <PostWithSlide type="MAIN" />

        {/* 예약상품 임시삭제 */}
        <div
          style={{
            display: "block",
            textAlign: "left",
            padding: "5px",
            backgroundColor: "#ffffff",
            fontSize: "0.9rem",
            color: primaryText,
            position: "relative",
            zIndex: 10,
          }}
        >
          <b> ★ 실시간 랭킹</b>
          <ProductListHorizontal url={"product/top10/" + "new"} />
        </div>
        <div
          style={{
            position: "relative",
            zIndex: 10,
          }}
        >
          <ImageRowList url={"banner"} />
        </div>

        <Festival />
        <div style={{ width: "100%" }}>
          {/* <div className="titleDiv">
          <b>공지사항</b>
        </div> */}
          <NoticeList />
        </div>
        {/* <span className="menuText">
          <h2>Ai 상품 추천</h2>
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            overflowY: "scroll",
            height: "55vw",
          }}
        >
          <RecommendListByUser />
        </div> */}

        {/* <div style={{ padding: "3px" }} /> */}
        <ProductList
          type={Constants.PRODUCT_FETCH_LIST_TYPE.NEW_ARRIVAL}
          lineSize={3}
          listSize={6}
          isAutoLoad={false}
        />
        {/* <MainCategoryTab
          categoryType1="애니메이션 카테고리"
          categoryType2="장르별"
          categoryType3="품목별"
        /> */}
        <div style={{ padding: "0px" }} />
        <ProductList
          type={Constants.PRODUCT_FETCH_LIST_TYPE.RESERVATION}
          lineSize={2}
          listSize={6}
          isAutoLoad={false}
        />
        <div style={{ padding: "5px" }} />

        {favoriteList ? (
          <div className="parentBox">
            <NormalBox
              alpha={0.7}
              title={
                <>
                  <div style={{ width: "100%" }}>
                    <div className="titleDiv">
                      <b>나의 최애 장르</b>
                    </div>
                    <FavoriteCategoryList />
                  </div>

                  {/* 예약상품 임시삭제 */}
                  <div style={{ padding: "13px" }} />
                  <b>고객님의 최애 장르 중 「인기 상품」만 모았어요★</b>
                  <ProductListHorizontal
                    url={"product/top20/1"}
                    animeId1={
                      favoriteList.length > 0 ? favoriteList[0].animeId : 0
                    }
                    animeId2={
                      favoriteList.length > 1 ? favoriteList[1].animeId : 0
                    }
                    animeId3={
                      favoriteList.length > 2 ? favoriteList[2].animeId : 0
                    }
                    animeId4={
                      favoriteList.length > 3 ? favoriteList[3].animeId : 0
                    }
                    animeId5={
                      favoriteList.length > 4 ? favoriteList[4].animeId : 0
                    }
                  />
                </>
              }
            />
          </div>
        ) : (
          <>
            <div>로그인 또는 최애 장르를 등록해주세요.</div>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <Button
                className="commonOutlinedBtn"
                style={{ height: "27px", fontSize: "0.8rem" }}
                sx={{ border: 1 }}
                onClick={() => {
                  window.location.href = "/member/favorite";
                }}
              >
                수정하기
              </Button>
            </div>
          </>
        )}
        <ExhibitionList />
        <div style={{ padding: "5px" }} />
        <div
          className="titleDiv"
          onClick={(e) => {
            location.href =
              "/product/list/" + Constants.PRODUCT_FETCH_LIST_TYPE.BEST_SELLER;
          }}
        >
          <b>《 인기 상품 》</b>
        </div>
        <ProductList
          type={Constants.PRODUCT_FETCH_LIST_TYPE.BEST_SELLER}
          lineSize={3}
          listSize={6}
          isAutoLoad={false}
        />
        <div style={{ padding: "5px" }} />
        <div className="centerContainer">
          <ImageListItem>
            <div
              style={{
                width: "100%",
                position: "relative",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                style={{
                  color: "#ffffff",
                  position: "absolute",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1,
                  left: "10vw",
                  bottom: "20px",
                }}
              >
                <b>오늘도 랜덤 카드깡✨</b>
              </div>
              <img
                src="/res/banner.png"
                style={{
                  width: "97%",
                  // objectFit: "",
                  height: "60px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </div>
          </ImageListItem>
        </div>
        <div
          style={{
            margin: "10px",
          }}
        >
          <Card
            sx={{
              width: "100%",
              padding: 0,
              borderRadius: "15px",
              boxShadow: "0px 0px 6px 1px " + primaryProductNode,

              borderColor: "#ffc1da",
              elevation: 4,
            }}
          >
            <div
              style={{
                // 중앙정렬
                display: "flex",
                justifyContent: "center",
                overflowY: "scroll",
                height: "55vw",
              }}
            >
              <ProductKeywordList keyword={"트레이딩 카드"} />
            </div>
          </Card>
        </div>

        {/* 예약상품 임시삭제 */}

        <div style={{ padding: "5px" }} />
        <div className="centerContainer">
          <ImageListItem>
            <div
              style={{
                width: "100%",
                position: "relative",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                style={{
                  color: "#ffffff",
                  position: "absolute",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1,
                  left: "10vw",
                  bottom: "20px",
                }}
              >
                <b>「반다이」 제품만 모아보았어요!</b>
              </div>
              <img
                src="/res/banner.png"
                style={{
                  width: "97%",
                  // objectFit: "",
                  height: "60px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </div>
          </ImageListItem>
        </div>
        <div
          style={{
            margin: "10px",
          }}
        >
          <Card
            sx={{
              width: "100%",
              padding: 0,
              borderRadius: "15px",
              boxShadow: "0px 0px 6px 1px " + primaryProductNode,

              borderColor: "#ffc1da",
              elevation: 4,
            }}
          >
            <div
              style={{
                // 중앙정렬
                display: "flex",
                justifyContent: "center",
                overflowY: "scroll",
                height: "55vw",
              }}
            >
              <ProductKeywordList keyword={"반다이"} />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default connector(index);

// export default withRouter(index);
